import React from 'react'
import { Helmet } from 'react-helmet'
import { useRouter } from 'next/router'

export const ReactHelmet = ({
  title,
  description,
  keywords = 'employment data, employment records, payroll data, payroll records'
}) => {
  const router = useRouter()
  const path = router.pathname

  return (
    <Helmet>
      <title>{title || 'Open Employment Data Standards'}</title>
      <meta charset="utf-8" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />

      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap"
        rel="stylesheet"
        type="text/css"
        crossOrigin="anonymous"
      />

      <link
        href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap"
        rel="stylesheet"
        type="text/css"
        crossOrigin="anonymous"
      />

      <link rel="shortcut icon" href="/favicon.ico" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, minimum-scale=1, user-scalable=no"
      />
      <meta name="theme-color" content="#000000" />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index,follow" />
      <meta
        name="description"
        content={
          description ||
          'The Open Employment Data Standards (OEDS) maintains an initiative aimed at standardizing how employment data are recorded, stored and distributed.'
        }
      />

      <meta itemProp="name" content="Open Employment Data Standards" />
      <meta data-react-helmet="true" itemProp="description" content={title || 'Open Employment Data Standards'} />
      <meta
        data-react-helmet="true"
        itemProp="image"
        content="https://res.cloudinary.com/argyle-media/image/upload/v1603895413/oeds/meta-oeds.png"
      />

      <meta property="og:type" content="website" />
      <meta data-react-helmet="true" property="og:url" content={`https://oeds.org${path}`} />
      <meta data-react-helmet="true" property="og:title" content={title || 'Open Employment Data Standards'} />
      <meta
        data-react-helmet="true"
        property="og:description"
        content={
          description ||
          'The Open Employment Data Standards (OEDS) maintains an initiative aimed at standardizing how employment data are recorded, stored and distributed.'
        }
      />
      <meta
        data-react-helmet="true"
        property="og:image"
        content="https://res.cloudinary.com/argyle-media/image/upload/v1603895413/oeds/meta-oeds.png"
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta data-react-helmet="true" property="twitter:url" content={`https://oeds.org${path}`} />
      <meta data-react-helmet="true" property="twitter:title" content={title || 'Open Employment Data Standards'} />
      <meta
        data-react-helmet="true"
        property="twitter:description"
        content={
          description ||
          'The Open Employment Data Standards (OEDS) maintains an initiative aimed at standardizing how employment data are recorded, stored and distributed.'
        }
      />
      <meta
        data-react-helmet="true"
        property="twitter:image"
        content="https://res.cloudinary.com/argyle-media/image/upload/v1603895413/oeds/meta-oeds.png"
      />
    </Helmet>
  )
}
